<template>
  <div class="logo-wrapper hover-scale-light">
    <Icon size="44px" name="icons:logo-coin" />
    <Icon size="37px" name="icons:logo-text" />
  </div>
</template>

<style scoped lang="scss">
.logo-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  gap: 16.5px;
}
</style>
