<script setup lang="ts">
import { useClipboard } from '~/utilities/helpers/clipboard';

const currYear = new Date().getFullYear();

const { copy, isCopied } = useClipboard();

const handleCopy = () => {
  copy('hi@newtify.io');
};
</script>

<template>
  <div id="footer" class="footer-wrapper">
    <footer class="footer">
      <div class="header-links">
        <nuxt-link to="/" class="logo">
          <base-logo-footer />
        </nuxt-link>
        <div class="links-social-wrapper">
          <nuxt-link
            to="https://t.me/newtify"
            class="social-link hover-scale-light"
            target="_blank"
            ><base-icon name="base/social_telegram" size="34px" filled />
          </nuxt-link>
          <nuxt-link
            to="https://discord.gg/WYWT9rKh6z"
            class="social-link hover-scale-light"
            target="_blank"
          >
            <base-icon name="base/social_discord" size="34px" filled />
          </nuxt-link>
          <nuxt-link
            to="https://x.com/Newtifyapp"
            class="social-link hover-scale-light"
            target="_blank"
            ><base-icon name="base/social_x" size="34px" filled />
          </nuxt-link>
          <nuxt-link
            to="https://www.youtube.com/@newtifyio"
            class="social-link hover-scale-light"
            target="_blank"
          >
            <base-icon name="base/social_youtube" size="34px" filled />
          </nuxt-link>
        </div>
      </div>

      <div class="middle-links-wrapper">
        <div class="middle-links">
          <nuxt-link
            to="#withdraw"
            class="links-item hover-scale-light font--b2"
          >
            Withdraw
          </nuxt-link>

          <nuxt-link to="/events" class="links-item hover-scale-light font--b2">
            Events
          </nuxt-link>

          <!-- <nuxt-link
            to="#advertisers"
            class="links-item hover-scale-light font--b2"
          >
            For Advertisers
          </nuxt-link> -->
        </div>
        <div class="email-link-wrapper">
          <span class="email-link-text font--b2">Our email:</span>
          <nuxt-link
            to="mailto::hi@newtify.io"
            class="email-link hover-scale-light font--b2"
          >
            hi@newtify.io
          </nuxt-link>
          <transition name="fade" mode="out-in">
            <button
              v-if="!isCopied"
              class="copy-email hover-scale-light"
              @click="handleCopy"
            >
              <base-icon name="base/copy" size="24px" filled />
            </button>
            <base-icon v-else name="base/valid_orange" size="24px" filled/>
          </transition>
        </div>
      </div>

      <div class="links-social-wrapper-mobile">
        <nuxt-link
          to="https://t.me/newtify"
          class="social-link hover-scale-light"
          target="_blank"
          ><base-icon name="base/social_telegram" size="34px" filled />
        </nuxt-link>
        <nuxt-link
          to="https://discord.gg/WYWT9rKh6z"
          class="social-link hover-scale-light"
          target="_blank"
        >
          <base-icon name="base/social_discord" size="34px" filled />
        </nuxt-link>
        <nuxt-link
          to="https://x.com/Newtifyapp"
          class="social-link hover-scale-light"
          target="_blank"
          ><base-icon name="base/social_x" size="34px" filled />
        </nuxt-link>
        <nuxt-link
          to="https://www.youtube.com/@newtifyio"
          class="social-link hover-scale-light"
          target="_blank"
        >
          <base-icon name="base/social_youtube" size="34px" filled />
        </nuxt-link>
      </div>

      <div class="separator"/>

      <div class="bottom-wrapper">
        <nuxt-link to="/policy" class="links-item hover-scale-light font--b3"
          >Privacy Policy</nuxt-link
        >

        <p class="copyright font--b3">© Copyright, {{ currYear }}</p>
      </div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.footer-wrapper {
  width: 100%;
  background: var(--grey-50);
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  padding: 82px 120px 32px;
  margin: 0 auto;
}

.header-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.links-social-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.social-link {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--primary-orange);
}

.middle-links-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.middle-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.links-item {
  text-decoration: none;
  color: var(--black);
}

.email-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.email-link-text {
  color: var(--primary-orange);
}

.email-link {
  color: var(--primary-orange);
  text-underline-offset: 5px;
}

.copy-email {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--grey-200);
  margin: 24px 0;
}

.bottom-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  color: var(--grey-800);
}

.links-social-wrapper-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .footer {
    padding: 40px 24px 32px;
  }

  .header-links {
    flex-direction: column;
  }

  .links-social-wrapper {
    display: none;
  }

  .links-social-wrapper-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
  }

  .middle-links-wrapper {
    flex-direction: column;
    gap: 32px;
  }

  .middle-links {
    flex-direction: column;
  }
}
</style>
