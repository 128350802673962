<script setup lang="ts">
useHead({
  link: [
  { rel: "icon", type: "image/png", sizes: "96x96", href: "/favicon-96x96.png" },
  { rel: "icon", type: "image/svg+xml", href: "/favicon.svg" },
  { rel: "shortcut icon", href: "/favicon.ico" },
  { rel: "apple-touch-icon", sizes:"180x180", href: "/apple-touch-icon.png" },
  { rel: "manifest", href: "/site.webmanifest" }, 
  ],
  meta: [
    { name: "google-site-verification", content: "Jap1eBBIjhD_Xhle6wmcUeDai9b8jV2zD2-EQ86PV4k" }
  ]
});
</script>

<template>
  <div class="content">
    <div class="content-wrapper">
      <app-header />
      <main class="wrapper">
        <slot/>
      </main>
      <app-footer />
    </div>
  </div>
</template>

<style scoped lang="scss">
.content {
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey-50);
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
